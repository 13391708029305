import React from 'react';
import { useSpring, animated } from 'react-spring';
import './GradientBg.scss';
import './Logo.css';
import LogoWhite from './Logo-White.png';
import styled from 'styled-components';
import useBoop from '../../hooks/use-boop.js';

const GradientBg = props => {

  const anime = useSpring({
    from: { transform: 'scale(0.75)' },
    to: { transform: 'scale(1)' },
    config: { mass: 1.5, 
      tension: 300,
      friction: 6, }
   });

  const [logoStyles, logoTrigger] = useBoop({
    scale: 1.1,
    rotation: 12,
    timing: 150,
    springConfig: {
      mass: 1.5,
      tension: 300,
      friction: 6,
    },
  });
  const LogoWrapper = styled(animated.span)`
  display: block;
  svg {
    display: block;
    stroke: white !important;
    fill: white !important;
  }
`;

  return (
    <div className="gradient-bg">
        
        <animated.div className="main-title" style={anime} {...props}>
        {
          <LogoWrapper style={logoStyles}>
            <img className="logo" src={LogoWhite} alt="" 
                  onClick={(e) => {e.preventDefault();logoTrigger();}}
                  draggable="false"
          />
          </LogoWrapper>
        }
        </animated.div>  
    </div>
  )
}

export default GradientBg;
